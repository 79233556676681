// require('./bootstrap');


import ClassicEditor from '@ckeditor/ckeditor5-build-classic/build/ckeditor.js';

require('@ckeditor/ckeditor5-build-classic/build/translations/pt-br.js');

ClassicEditor
    .create( document.querySelector('.ckeditor'), {
        language: 'pt-br'
    } )
    .catch( error => {
        console.log( error );
    } );
